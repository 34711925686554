<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <div
          class="header-logo"
          @click="pushToMainPage()"
          style="cursor: pointer"
        >
          <div><img src="../../assets/logo.png" alt="logo" /></div>
        </div>
        <nav class="list">
          <div class="list__item">
            <span @click="scrollToId('benefits')">Our Advantages</span>
          </div>
          <div class="list__item">
            <span @click="scrollToId('products')">Our Products</span>
          </div>
          <div class="list__item">
            <span @click="scrollToId('aboutUs')">About Us</span>
          </div>
          <div class="list__item">
            <span @click="scrollToId('productsGallery')">Gallery</span>
          </div>
        </nav>
        <div class="header-btn">
          <button class="custom-btn" @click="dialogVisible = true">
           Contact us
          </button>
        </div>
        <div class="toggle-btn">
          <v-btn icon x-large @click="mobileDrawer = !mobileDrawer"
            ><v-icon color="rgba(250, 250, 250, 1)">mdi-menu</v-icon></v-btn
          >
        </div>
      </div>
      <dialogComponent
        :visible="dialogVisible"
        @close="dialogVisible = false"
        v-if="dialogVisible"
      />
      <navigationDrawer
        :visible="mobileDrawer"
        @close="mobileDrawer = false"
        v-if="mobileDrawer"
      />
    </div>
  </header>
</template>

<script>
import dialogComponent from "@/components/dialogs/dialogComponent.vue";
import navigationDrawer from "@/components/dialogs/navigationDrawer.vue";
export default {
  data: () => ({
    dialogVisible: false,
    mobileDrawer: false,
  }),
  components: {
    dialogComponent,
    navigationDrawer,
  },
  methods: {
    async scrollToId(id) {
      if (this.$route.path != "/photos") {
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
            top: targetPosition - scrollOffset,
            behavior: 'smooth'
        });
      } else {
        await this.$router.push("/");
        const element = document.getElementById(id);
        const scrollOffset = 120;
        const targetPosition = element.offsetTop;
        window.scrollTo({
            top: targetPosition - scrollOffset,
        });
      }
    },
    async pushToMainPage() {
      if ((await this.$route.path) != "/") {
        this.$router.push("/");
        window.scrollTo(0, 0);
      } else {
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>
<style scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 20;
}
.container {
  max-width: 1460px;
  padding: 0;
}
.header__inner {
  padding: 20px 130px;
  background: linear-gradient(90deg, #15471d 0%, #33ad47 100%);
  min-height: 110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.custom-btn {
  color: var(--black, #1b1b1b);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 20px;
  border-radius: 4px;
  background: var(--white, #fafafa);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: all 0.4s;
}
.custom-btn:hover {
  background: #d1d1d1;
}
.list {
  display: flex;
  align-items: center;
}
.list__item + .list__item {
  margin-left: 32px;
}
.list__item span {
  text-decoration: none;
  color: var(--white, #fafafa);
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all 0.4s;
  cursor: pointer;
}
.list__item span:hover {
  opacity: 0.7;
}
.toggle-btn {
  display: none;
}
@media (max-width: 1260px) {
  .header__inner {
    padding: 10px 60px;
  }
}
@media (max-width: 1120px) {
  .header__inner {
    padding: 10px 20px;
  }
}
@media (max-width: 1040px) {
  .list {
    display: none;
  }
  .custom-btn {
    display: none;
  }
  .toggle-btn {
    display: inline-block;
  }
}
</style>
